<template>
    <div class="risk-view">
        <div class="ledger-shopsearch">
            <LedgerList title="风险台账" :list="ledgerList" @onClick="onLedgerClick" :active="ledgerActive"/>
            <ShopSearch title="企业列表" :list="shopList" @onSearch="onShopSearch" @onClick="onShopClick" :active="shopActive"/>
        </div>
        <div :class="{'table-charts-view': true, charts: !ledgerActive}">
            <div class="table-modal" v-if="ledgerActive">
                <TableList title="风险台账清单" @onClick="onTableClose" />
            </div>
            <div class="charts-modal" v-if="!ledgerActive">
                <ChartsView :existRisk="existRiskFormat" :inherentRisk="inherentRiskFormat" :enterpriseRiskList="enterpriseRiskFormat"/>
            </div>
        </div>
        <div class="footer-cricle-view" v-if="!ledgerActive">
            <div class="item" v-for="item in existRiskFormat" :key="item.name">
                <CircleNumber :data="item" />
            </div>
        </div>
    </div>
</template>

<script>

import EventBus from '@/commons/event-bus.js'

import LedgerList from '@/components/LedgerList.vue';
import ShopSearch from '@/components/ShopSearch.vue';
import CircleNumber from "@/components/CircleNumber.vue";
import TableList from './table-list/Index.vue'
import ChartsView from './ChartsView.vue'

const riskName = {
    risk_level_id_major_total: {id:'1', name: '重大风险', color: '#E86452' },
    risk_level_id_more_total: {id:'2', name: '较大风险', color: '#FFB462' },
    risk_level_id_common_total: {id:'3', name: '一般风险',color: '#FFD470' },
    risk_level_id_low_total: {id:'4', name: '低风险', color: '#2385FF'}
}

const riskNameType = {
    risk0: {id:'0', name: '未评估综合风险等级', color: '#FFFFFF' },
    risk1: {id:'1', name: '重大风险', color: '#E86452' },
    risk2: {id:'2', name: '较大风险', color: '#FFB462' },
    risk3: {id:'3', name: '一般风险',color: '#FFD470' },
    risk4: {id:'4', name: '低风险', color: '#2385FF'}
}

export default {
    name: 'risk',
    components: { LedgerList, ShopSearch, TableList, ChartsView, CircleNumber },
    data(){
        return {
            ledgerActive: null,
            shopActive: null,
            ledgerList: [
               { key: '1', name: '风险台账清单' }
            ],
            enterpriseList: [], //左侧企业列表 可搜索
            enterpriseRiskList: [], //右侧风险分布数据
            shopList: [], // 左侧企业搜索结果
            inherentRisk: [], //固有风险
            existRisk: [],    //现有风险
            mapData:[  //地图数据
                {

                }
            ]

        }
    },
    computed:{
       existRiskFormat(){
           return Object.keys(this.existRisk).map(key=>{
               const {name, color} = riskName[key];
               const value = this.existRisk[key];
               return { name, color, value, y: value };
           })
       },
       inherentRiskFormat(){
           return Object.keys(this.inherentRisk).map(key=>{
               const {name, color} = riskName[key];
               const value = this.inherentRisk[key];
               return { name, color, value, y: value };
           })
       },
       enterpriseRiskFormat(){
            let xdata = [];
            let exist = {};
            let inherent = {};

           this.enterpriseRiskList.forEach(item=>{
                xdata.push(item.enterprise_name);
                const {
                    inherent_assess_risk_level_info: inherent_assess,
                    exist_assess_risk_level_info: exist_assess
                } = item.assess_risk_level_info;

                Object.keys(exist_assess).forEach( key => {
                    inherent[key] = inherent[key] ? inherent[key] : {name: '', color: '', data: []};
                    inherent[key].name = riskName[key].name;
                    inherent[key].color = riskName[key].color;
                    inherent[key].data.push(inherent_assess[key]);

                    exist[key] = exist[key] ? exist[key] : {name: '', color: '', data: []};
                    exist[key].name = riskName[key].name;
                    exist[key].color = riskName[key].color;
                    exist[key].data.push(exist_assess[key]);
                })
           });

           const data = [
               { xdata, name: '固有', ydata: Object.values(inherent).reverse() },
               { xdata, name: '现有', ydata: Object.values(exist).reverse() }
            ]
           return data;
       }
    },
    mounted(){
        //页面初始化接口
        this.initData();
    },
    methods:{
        //页面初始化
        initData(){
            this.getEnterprises();
            this.getRiskBaseInfo();
            this.getEnterpriseRiskList();
        },

        //获得企业信息列表
        async getEnterprises(){
            //04-基础-获得下属企业信息列表
            let [res, err] = await this.$Fx('/Api_Area/Area/Area/getEnterpriseBaseList');
            if(!err && res){
                 this.enterpriseList = res.data.enterprise_list || [];
            }
        },

        //获得区域风险基础信息
        async getRiskBaseInfo(){
            //06-风险-获得区域风险基础信息
            let [res, err] = await this.$Fx('/Api_Area/Risk/RiskPoint/getRiskBaseInfo');
            if(!err && res){
                this.inherentRisk = res.data.risk_base_info.inherent_assess_risk_level_info || {};
                this.existRisk = res.data.risk_base_info.exist_assess_risk_level_info || {};
            }
        },

        //获得企业风险分布
        async getEnterpriseRiskList(){
            //07-风险-获得下属企业风险信息列表
            let [res, err] = await this.$Fx('/Api_Area/Risk/RiskPoint/getEnterpriseRiskList');
            if(!err && res){
                 this.enterpriseRiskList = res.data.enterprise_risk_list || [];
                 this.setMapData()
            }
        },

        // 设置map的marker信息
        setMapData(){
            const markers = this.enterpriseRiskList.map(item => {
               const {
                    enterprise_id,
                    enterprise_name,
                    grid_info:{
                        longitude: long, // 经度
                        latitude: lat // 维度
                    },
                    enterprise_risk_level_id: level_id,
                    assess_risk_level_info:{
                        exist_assess_risk_level_info: {
                            risk_level_id_major_total: major,   //重大风险
                            risk_level_id_more_total: more,  //较大
                            risk_level_id_common_total: common, //一般风险
                            risk_level_id_low_total: low,   //低风险
                        }
                    }
               } = item;
               return {
                    enterprise_id,
                    enterprise_name,
                    long,
                    lat,
                    level_id,
                    level_name: riskNameType[`risk${level_id}`].name,
                    level_color: riskNameType[`risk${level_id}`].color,
                    type: '风险',
                    info: { major,more,common,low }
               }
           })

           EventBus.$emit("changeMarkers", markers);
        },

        //点击风险台账清单
        onLedgerClick(item){
            const isActive = this.ledgerActive && item.key === this.ledgerActive.key;
            this.ledgerActive =  isActive ? null : item;
        },

        //点击表格关闭按钮
        onTableClose(flag){
            this.ledgerActive = flag;
        },

        //点击企业列表中的某个企业
        onShopClick(item){
            const isActive = this.shopActive && item.enterprise_id === this.shopActive.enterprise_id;
            this.shopActive =  isActive ? null : item;

            const { grid_info: { latitude: lat, longitude: long } } = item;
            EventBus.$emit("moveCenter", { lat, long } );
        },

        //搜索企业列表
        onShopSearch(value){
           this.shopList = value ? this.enterpriseList.filter( item=> item.enterprise_name.includes(value) ) : [];
        },
    }
}
</script>

<style lang="less">
.risk-view{

    height: 100%;
  //height: calc(100% - 70px);
    display: flex;
    padding: 20px;
    box-sizing: border-box;
    position: relative;
    .ledger-shopsearch{
        display: flex;
        flex-direction: column;
        padding: 60px 0 0 0px;
    }
    .table-charts-view{
        flex: 1 1 auto;
        padding-left: 15px;
        &.charts{
            display: flex;
            justify-content: flex-end;
        }
        .table-modal{
            width: 100%;
            height: 100%;
            border-radius: 5px;
            pointer-events: all;
        }
        .charts-modal{
            width: 325px;
            height: 100%;
            border-radius: 5px;
            pointer-events: all;
        }
    }
    .footer-cricle-view{
        // border: 1px solid #aaa;
        position: absolute;
        bottom: 20px;
        left: 20px;
        pointer-events: all;
        display: flex;
    }
}

</style>
