<template>
    <div class="chart-views">
        <div class="inherent-risk-box">
            <HighchartsPie id="inherentRiskChart" :data="inherentRisk" title="企业固有风险" v-if="inherentRisk && inherentRisk.length > 0"/>
        </div>
        <div class="existing-risk-box">
            <HighchartsPie id="existRiskChart" :data="existRisk" title="企业现有风险" v-if="existRisk && existRisk.length > 0" />
        </div>
        <div class="store-distribution-box" :style="{height:`${height}px`}">
            <MultipleBarCharts id="enterpriseRiskList" :data="enterpriseRiskList" title="企业风险分布"/>
        </div>
    </div>
</template>

<script>

import HighchartsPie from '@/components/HighchartsPie.vue';
import MultipleBarCharts from '@/components/MultipleBarCharts.vue';

export default {
    name: 'RiskChartsView',
    components: { HighchartsPie, MultipleBarCharts },
    props: ['existRisk', 'inherentRisk','enterpriseRiskList'],
     data(){
        return {
            height:350
        }
    },
    watch:{
        hiddenDangerCatetoryList(cur){
            const h = cur[0].xdata.length * 15  + 30;
            this.height =  Math.min(350, Math.max(200, h));
        }
    }
}
</script>

<style lang="less">
.chart-views{
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    .inherent-risk-box{
        height: 154px;
    }
    .existing-risk-box{
        height: 154px;
        margin: 20px 0;
    }


}
</style>
