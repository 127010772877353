<template>
  <div class="filter-box">
    <div class="filter-group">
        <div class="filter-item">
            <el-select 
            v-model="filterGroups.enterprise_id" 
            filterable 
            clearable 
            @change="onEnterpriseChange"
            placeholder="请选择企业" 
            popper-class="risk-table-filter-popper">
                <el-option
                    v-for="item in enterprise_list"
                    :key="item.enterprise_id"
                    :label="item.enterprise_name"
                    :value="item.enterprise_id"
                >
                </el-option>
            </el-select>
        </div>
        <div class="filter-item">
            <el-select 
            v-model="filterGroups.depatment_id" 
            filterable
            clearable  
            placeholder="请选择责任部门" 
            popper-class="risk-table-filter-popper">
                <el-option
                    v-for="item in department_list"
                    :key="item.depatment_id"
                    :label="item.department_name"
                    :value="item.depatment_id"
                >
                </el-option>
            </el-select>
        </div>
        <div class="filter-item">
            <el-select 
            v-model="filterGroups.risk_point_id" 
            filterable 
            clearable 
            placeholder="请选择风险点" 
            popper-class="risk-table-filter-popper">
                <el-option
                    v-for="item in risk_point_list"
                    :key="item.risk_point_id"
                    :label="item.risk_point_name"
                    :value="item.risk_point_id"
                >
                </el-option>
            </el-select>
        </div>
        <div class="filter-item">
            <el-select 
            v-model="filterGroups.inherent_assess_risk_level_id" 
            filterable 
            clearable 
            placeholder="请选择固有风险等级" 
            popper-class="risk-table-filter-popper">
                <el-option
                    v-for="item in risk_level_list"
                    :key="item.risk_level_id"
                    :label="item.risk_level_name"
                    :value="item.risk_level_id"
                >
                </el-option>
            </el-select>
        </div>
        <div class="filter-item">
            <el-select 
            v-model="filterGroups.exist_assess_risk_level_id" 
            filterable 
            clearable 
            placeholder="请选择现有风险等级" 
            popper-class="risk-table-filter-popper">
                <el-option
                    v-for="item in risk_level_list"
                    :key="item.risk_level_id"
                    :label="item.risk_level_name"
                    :value="item.risk_level_id"
                >
                </el-option>
            </el-select>
        </div>
    </div>
    <div class="btn-box">
        <el-button @click="onReset">重置</el-button>
        <el-button @click="onSearch">查询</el-button>
    </div>
</div>
</template>

<script>
export default {
    name: 'FilterList',
    props: ['onFilterSearch'],
    data(){
        return {
            enterprise_list: [], //企业列表
            department_list: [], //企业下-部门列表
            risk_point_list: [], //企业下-风险点列表
            risk_level_list: [], //风险等级列表
            filterGroups:{
              enterprise_id: '',  //当前选择的企业
              depatment_id: '',  //当前选择的部门
              risk_point_id: '',   //当前选择的风险点
              inherent_assess_risk_level_id: '',  //当前固有风险等级
              exist_assess_risk_level_id: '',  //当前现有风险等级
            },
        }
    },
    mounted(){
        this.initFilter();
    },
    methods:{
        initFilter(){
            this.getEnterpriseBaseList();
            this.getRiskAccountList();
        },

        // 查询
        onSearch(){
            const {
                enterprise_id,  
                depatment_id: department_id, 
                risk_point_id,
                inherent_assess_risk_level_id, 
                exist_assess_risk_level_id,
            } = this.filterGroups;

            const filter = {
                enterprise_id,  
                department_id, 
                risk_point_id,
                inherent_assess_risk_level_id, 
                exist_assess_risk_level_id,
            }

            this.$emit('onFilterSearch', filter)
        },

        // 重置
        onReset(){
            this.filterGroups = {
                enterprise_id: '',  
                depatment_id: '',   //后台这个单词拼错了 department_id
                risk_point_id: '',   //当前选择的风险点
                inherent_assess_risk_level_id: '',  //当前固有风险等级
                exist_assess_risk_level_id: '',  //当前现有风险等级
            };
        },

        //企业下拉框数据变化时候，需要更新 企业下-部门列表 和 企业下-风险点列表
        onEnterpriseChange(){
            this.getDepartmentListInEnterprise();
            this.getRiskPointList();
        },

        //获得企业列表
        async getEnterpriseBaseList(){
            // 04-基础-获得下属企业信息列表
            let [res, err] = await this.$Fx('/Api_Area/Area/Area/getEnterpriseBaseList');
            if(!err && res){
                this.enterprise_list = res.data.enterprise_list || [];
            }
        },

        //获得企业下-部门列表
        async getDepartmentListInEnterprise(){
            const enterprise_id = this.filterGroups.enterprise_id;
            if(!enterprise_id){
                this.risk_point_list = [];
                return ;
            }
            // 09-风险-风险-获得企业风险点列表
            const params = { enterprise_id };
            // 05-基础-获得下属某企业部门列表
            let [res, err] = await this.$Fx('/Api_Area/Area/Area/getDepartmentListInEnterprise', params);
            if(!err && res){
                this.department_list = res.data.department_list || [];
            }
        },

        //获得企业下-风险点列表
        async getRiskPointList(){
            const enterprise_id = this.filterGroups.enterprise_id;
            if(!enterprise_id){
                this.risk_point_list = [];
                return ;
            }
            // 09-风险-风险-获得企业风险点列表
            const params = { enterprise_id };
            let [res, err] = await this.$Fx('/Api_Area/Risk/RiskPoint/getRiskPointList', params);
            if(!err && res){
                this.risk_point_list = res.data.risk_point_list || [];
            }
        },

        //获得风险等级列表
        async getRiskAccountList(){
            //10-风险-获得风险等级列表
            let [res, err] = await this.$Fx('/Api_Area/Base/RiskPoint/getRiskLevelList');
            if(!err && res){
                this.risk_level_list = res.data.risk_level_list || [];
            }
        }

    }
}
</script>

<style lang="less" scope>
.risk-view{
    .filter-box{
        display: flex;
        justify-content: space-between;
        .filter-group{
            display: flex;
            .filter-item{
                padding: 0 8px;
            }
            .el-input__inner{
                height: 30px;
                color: #fff;
                background: rgba(76, 156, 255, 0.2);
                border: 1px solid #3C79B1;
                border-radius: 3px;
                font-size: 12px;
            }
            .el-input__icon{
                line-height: 30px;
            }
        }
        .btn-box{
            display: flex;
            padding-left: 10px;
            .el-button{
                border: 1px solid #3C79B1;
                background: #154382;
                border-radius: 5px;
                color: #fff;
                padding: 4px 20px;
                transition: all 0.3s;
                &:hover{
                    background-color: rgb(41, 136, 245);
                }
            }
        }
    }
}

.risk-table-filter-popper {
  background: rgba(4, 17, 34, 0.9);
  border: 1px solid #3c79b1;
  border-radius: 5px;
  color: #fff;
  .el-select-dropdown__list {
    .el-select-dropdown__item {
      height: 30px;
      color: #fff;
      line-height: 30px;
    }
    .el-select-dropdown__item.hover,
    .el-select-dropdown__item:hover {
      background-color: rgba(255, 255, 255, 0.1);
    }
  }
  .popper__arrow {
    border-bottom-color: #3c79b1 !important;
    &::after {
      border-bottom-color: rgba(4, 17, 34, 0.9) !important;
    }
  }
}
</style>