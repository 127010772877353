<template>
    <BorderBox :bg="true">
        <div class="multiple-bar-charts">
            <Title :text="title" />
            <div class="toggle-btn">
                <div v-for="(item, i) in data" :key="item.name" :class="{btn: item, active: active && active == i}" @click="toggle(i)">
                    {{item.name}}
                </div>
            </div>
            <div class="chart-view">
                <BarChartHor :id="id" :data="activeData" v-if="activeData && activeData.xdata.length > 0"/>
            </div>
        </div>
    </BorderBox>
</template>

<script>

import BorderBox from '@/components/BorderBox.vue';
import BarChartHor from '@/components/BarChartHor.vue';
import Title from '@/components/Title.vue';

import * as echarts from 'echarts/core';
// 引入柱状图图表，图表后缀都为 Chart
import { BarChart } from 'echarts/charts';

import { GridComponent,TooltipComponent, TitleComponent, LegendComponent } from 'echarts/components';
import { LabelLayout, UniversalTransition } from 'echarts/features';
import { CanvasRenderer } from 'echarts/renderers';

echarts.use([
  GridComponent,
  TooltipComponent,
  LegendComponent,
  TitleComponent,
  BarChart,
  LabelLayout,
  UniversalTransition,
  CanvasRenderer
]);

export default {
    name: 'MultipleBarCharts',
    components: { BorderBox, BarChartHor, Title },
    props: ['data', 'id', 'title'],
    data(){
        return {
            active: '0'
        }
    },
    computed:{
        activeData(){
            return this.data[this.active]
        }
    },
    methods:{
        toggle(i){
            this.active = `${i}`;
        }
    }
}
</script>

<style lang="less">
.multiple-bar-charts{
    width: 100%;
    height: 100%;
  //background: red;
    padding: 10px;
    box-sizing: border-box;
    display: flex;
    flex-direction: column;
    position: relative;
    .toggle-btn{
        position: absolute;
        top: 20px;
        right: 20px;
        display: flex;
        z-index: 100;
        cursor: pointer;
        .btn{
            width: 50px;
            height: 24px;
            background: linear-gradient(0deg, #305A85, #305A85);
            border-radius: 5px;
            font-size: 14px;
            font-family: "Alibaba-PuHuiTi-Regular";
            font-weight: 400;
            color: rgba(255, 255, 255, 0.6);
            display: flex;
            justify-content: center;
            align-items: center;
            margin-left: 5px;
            &.active{
                background: linear-gradient(0deg, #7DEBFF 0%, #154382 85%);
                color: rgba(255, 255, 255, 1);
            }
        }
    }
    .chart-view{
        flex: 1 1 auto;
        height: 100%;
    }
}
</style>
