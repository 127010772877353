<template>
  <div class="result-table">
      <Table :tableData="tableData" >
        <el-table-column
        prop="No"
        label="序号"
        width="50"
        align="center"
      ></el-table-column>
      <el-table-column
        prop="enterprise_name"
        label="企业"
        width="150"
        show-overflow-tooltip
      ></el-table-column>
      <el-table-column
        prop="risk_point_name"
        label="风险点"
        width="160"
        show-overflow-tooltip
      ></el-table-column>
      <el-table-column
        prop="object_name"
        label="评估对象"
        width="150"
        show-overflow-tooltip
      ></el-table-column>
      <el-table-column
        prop="harmful_factory_name"
        label="危险有害因素"
        show-overflow-tooltip
      ></el-table-column>
      <el-table-column
        prop="harmful_factory_aftermath_name"
        label="事故后果"
        width="100"
        show-overflow-tooltip
      ></el-table-column>
      <el-table-column
        prop="inherent_assess_risk_level_name"
        label="固有风险等级"
        width="90"
        show-overflow-tooltip
      >
       <template slot-scope="scope">
          <span :style="{color: TABLE_RISK_LEVEL_COLOR[scope.row.inherent_assess_risk_level_name]}">
            {{ scope.row.inherent_assess_risk_level_name }}
          </span>
        </template>
      </el-table-column>
      <el-table-column
        prop="exist_assess_risk_level_name"
        label="现有风险等级"
        width="90"
        show-overflow-tooltip
      >
       <template slot-scope="scope">
          <span :style="{color: TABLE_RISK_LEVEL_COLOR[scope.row.exist_assess_risk_level_name]}">
            {{ scope.row.exist_assess_risk_level_name }}
          </span>
        </template>
      </el-table-column>
      <el-table-column
        prop="department_highest_control_level_name"
        label="管控层级"
        width="100"
        show-overflow-tooltip
      ></el-table-column>
      <el-table-column label="责任部门" width="150" show-overflow-tooltip>
        <template slot-scope="scope">
          <span v-for="(item, i) in scope.row.department_position_list" :key="i">
            {{ `${item.department_name}-${item.position_name}` }}
            <span v-if="i < scope.row.department_position_list.length - 1">,</span>
          </span>
        </template>
      </el-table-column>
      <el-table-column
        label="管控措施"
        width="60"
        show-overflow-tooltip
        align="center"
      >
        <template slot-scope="scope">
          <el-popover
            placement="bottom-end"
            width="600"
            trigger="click"
            popper-class="table-tooltips"
          >
            <div class="control-measures-popup">
              <div class="title">管控措施</div>
              <div class="list">
                <div class="item">
                  <div class="name">工程技术措施</div>
                  <div class="texts">
                      <div v-for="item in scope.row.engine_technology_list" :key="item.control_id">{{item.control_content}}</div>
                  </div>
                </div>
                <div class="item">
                  <div class="name">管理类措施</div>
                  <div class="texts">
                      <div v-for="item in scope.row.management_list" :key="item.control_id">{{item.control_content}}</div>
                  </div>
                </div>
                <div class="item">
                  <div class="name">教育培训措施</div>
                  <div class="texts">
                      <div v-for="item in scope.row.education_training_list" :key="item.control_id">{{item.control_content}}</div>
                  </div>
                </div>
                <div class="item">
                  <div class="name">个体防护措施</div>
                  <div class="texts">
                      <div v-for="item in scope.row.personal_protection_list" :key="item.control_id">{{item.control_content}}</div>
                  </div>
                </div>
                <div class="item">
                  <div class="name">应急措施</div>
                  <div class="texts">
                      <div v-for="item in scope.row.emergency_list" :key="item.control_id">{{item.control_content}}</div>
                  </div>
                </div>
              </div>
            </div>
            <div class="num" slot="reference">
              {{ scope.row.control_total }}
            </div>
          </el-popover>
        </template>
      </el-table-column>
      </Table>  
  </div>
</template>

<script>

import Table from '@/components/Table.vue';

export default {
  name: "TableList",
  props: ['tableData'],
  components: { Table },
  data(){
    return {
      TABLE_RISK_LEVEL_COLOR: {
        '重大风险': '#E86452',
        '较大风险': '#FFB462',
        '一般风险': '#FFD470',
        '低风险': '#2385FF'
      }
    }
  }
};
</script>

<style lang="less">
.result-table{
  flex: 1 1 auto;
  overflow: auto;
}
.control-measures-popup{
  display: flex;
  flex-direction: column;
  font-size: 14px;
  font-weight: 400;
  .title{
    font-family: 'AliHYAiHei';
    color: #FFFFFF;
    line-height: 17px;
  }
  .list{
    flex: 1 1 auto;
    display: flex;
    flex-direction: column;
    max-height: 500px;
    overflow: auto;
    .item{
        display: flex;
        flex-direction: column;
        margin: 10px 0 5px 0;
        .name{
          margin: 0 0 3px 0;
          font-size: 16px;
          font-family: 'Alibaba-PuHuiTi-Bold';
          font-weight: bold;
          color: #FFFFFF;
          line-height: 17px;
        }
        .texts{
          font-family: 'Alibaba-PuHuiTi-Regular';
          color: rgba(255, 255, 255, 0.7);
          line-height: 17px;
        }
    }
  }
}
</style>