<template>
    <BorderBox :bg="true">
        <div class="table-list">
            <Title :text="title" class="title-close">
                <div class="close" @click="closeTable"> <i class="el-icon-close"></i> </div> 
            </Title>    
            <FilterList @onFilterSearch="onFilterSearch"/>
            <TableList :tableData="tableData" />
            <Pagination :data="pagination" @onSizeChange="onSizeChange" @onCurrentChange="onCurrentChange" />
        </div>
    </BorderBox>
</template>

<script>

import Title from '@/components/Title.vue';
import FilterList from './FilterList.vue';
import TableList from './TableList.vue';
import Pagination from '@/components/PaginationVew.vue';

import BorderBox from '@/components/BorderBox.vue';

export default {
    name: 'table-list',
    components: { Title, FilterList, TableList, Pagination, BorderBox },
    props: ['title', 'onClick'],
    data(){
        return {
            tableData: [
                // {
                //     No, //序号   
                //     enterprise_name, //企业  
                //     risk_point_name, //风险点   
                //     object_name, //评估对象   
                //     harmful_factory_name, //危险有害因素
                //     harmful_factory_aftermath_name, //事故后果  
                //     inherent_assess_risk_level_name,	//固有风险等级名称
                //     exist_assess_risk_level_name,	//现有风险等级名称
                //     department_highest_control_level_name, //管控层级名称
                //     department_position_list,             //责任部门及岗位列表，需要遍历
                //     control_total,           //管控措施总数   
                //     engine_technology_list,  //工程技术措施
                //     management_list,  //管理类措施
                //     education_training_list,  //教育培训措施
                //     personal_protection_list,  //个体防护措施
                //     emergency_list,  //应急措施   
                // }
            ],
            filters: {
                enterprise_id: '', // 企业id
                department_id: '', // 企业部门id 
                risk_point_id: '', // 风险点
                inherent_assess_risk_level_id: '', // 固有风险点
                exist_assess_risk_level_id: '', // 现有风险点
            },
            pagination: {  //分页信息
                page: 1,  // 页数(0-全部)
                count: 10, // 每页数量
                total: 0
            }
        }
    },
    mounted() {
        this.getRiskAccountList();
    },
    methods: {
        //关闭表格事件
        closeTable(){
            this.$emit('onClick', null);
        },

        // 过滤查询
        onFilterSearch(filter){
           this.filters = filter;
           //重新更新表格数据
           this.getRiskAccountList();
        },

        // 分页组件页面size变化
        onSizeChange(count){
            this.pagination = {...this.pagination, count};  
            this.getRiskAccountList();
        },

        // 分页组件页面当前页变化
        onCurrentChange(page){
            this.pagination = {...this.pagination, page};
            this.getRiskAccountList();
        },

        //获得区域风险表格数据
        async getRiskAccountList(){
            //08-风险-获得风险台账清单列表
            const params = {...this.filters, pagination: this.pagination};
            let [res, err] = await this.$Fx('/Api_Area/Risk/RiskPoint/getRiskAccountList', params);
            if(!err && res){
                this.tableData = res.data.risk_account_list || [];
                //更新分页信息
                const { count, total} = res.data.paginated;
                this.pagination = { ...this.pagination, count: Number(count), total: Number(total) };
            }
        }
    },
}
</script>

<style lang="less">
.risk-view{
    .table-list{
        width: 100%;
        height: 100%;
        display: flex;
        flex-direction: column;
        padding: 10px;
        box-sizing: border-box;
    }
}

.title-close{
    display: flex;
    justify-content: space-between;
    align-items: center;
    box-sizing: border-box;
    height: 40px;
    padding-bottom: 15px;
    padding-left: 10px;
    .close{
        width: 20px;
        height: 20px;
        color: #fff;
        font-size: 18px;
        font-weight: bold;
        cursor: pointer;
        &:hover{
            transform: scale(1.2);
            color: #7DEBFF;
        }
    }
}

</style>